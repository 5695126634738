import React from "react";
import AboutHeroSection from "../components/about/AboutHeroSection";
import TrustedSection from "../components/about/TrustedSection";

const About = () => {
  return (
    <div className="md:pt-24 py-10">
      <AboutHeroSection />
      <TrustedSection/>
    </div>
  );
};

export default About;
