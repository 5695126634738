import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuItem = [
    {
      name: "Home",
      path: "",
    },
    {
      name: "About Us",
      path: "about-us",
    },
    {
      name: "Features",
      path: "features",
    },
    {
      name: "Pricing",
      path: "pricing",
    },
    {
      name: "Support Team",
      path: "support-team",
    },
    {
      name: "Contact",
      path: "contact-us",
    },
  ];
  return (
    <>
      {/* Navbar Container with 80% Width */}
      <div className="bg-white w-5/6 mx-auto flex items-center justify-between md:py-4 px-5 rounded-full border-t shadow-lg">
        {/* Logo */}
        <div className="flex-1">
          <NavLink to="/" className="text-2xl font-bold">
            <img
              src="/logo/netfee logo.png"
              alt=""
              className="w-20 ms-2"
              // style={{ filter: "invert(100%)" }}
            />
          </NavLink>
        </div>

        {/* Navbar Menu - Centered */}
        <div className="desktop-navbar justify-center lg:pe-5 md:pe-1">
          <ul className="flex lg:gap-4 md:gap-1 gap-3 items-center">
            {menuItem &&
              menuItem.map((item) => (
                <li key={item}>
                  <NavLink
                    to={`/${item.path}`}
                    className={({ isActive }) =>
                      `py-2 px-2 rounded-md ${
                        isActive
                          ? "bg-indigo-700 text-white"
                          : "hover:border-b-2 hover:border-indigo-600 text-gray-800"
                      }`
                    }
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
        {/* Hamburger Icon for Mobile */}
        <div className="mobile-navbar">
          <button
            className="btn btn-square btn-ghost text-gray-600"
            onClick={() => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              // Close Icon
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              // Hamburger Icon
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-6 h-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div
            className={`absolute top-full left-0 w-full mx-auto bg-gray-600 p-4 rounded-2xl overflow-hidden transition-all duration-700 ease-out transform ${
              isOpen
                ? "max-h-[500px] translate-y-0 opacity-100 visible mt-1"
                : "max-h-0 -translate-y-full opacity-0 invisible"
            } md:hidden`}
          >
            <ul className="flex flex-col items-center gap-4">
              {menuItem &&
                menuItem.map((item) => (
                  <li key={item} onClick={() => setIsOpen(false)}>
                    <NavLink
                      to={`/${item.path}`}
                      className={({ isActive }) =>
                        `py-2 px-7 rounded-full ${
                          isActive ? "bg-fuchsia-600 text-white" : "text-white"
                        }`
                      }
                    >
                      {item.name}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
