import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Gallery = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "100px",
    slidesToShow: 3,
    speed: 500,
    autoplay: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    "/images/showcase1.jpg",
    "/images/showcase2.jpg",
    "/images/showcase3.jpg",
    "/images/showcase4.jpg",
    "/images/showcase5.jpg",
  ];

  return (
    <div className="text-gray-700 md:mt-8 py-16 container w-5/6 mx-auto">
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold font-extrabold">
          Explore Our Software Gallery
        </h2>
        <p className="text-gray-600 text-xl mt-4">
          Checkout our image gallery below which contains some exciting features
          of Netfee.
        </p>
      </div>

      <div className="relative">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index} className="px-4">
              <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden">
                <img
                  src={image}
                  alt={`Showcase ${index + 1}`}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Gallery;
