import React from "react";

const ReturnRefundPolicy = () => {
  return (
    <div className="return-refund-policy p-24 md:p-24 bg-gray-50 shadow-xl text-gray-700 container w-5/6 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Return and Refund Policy</h2>
      <p className="mb-4">
        Thank you for shopping with Shunno Software. We appreciate your business
        and strive to provide the best services and products. If you are not
        entirely satisfied with your purchase, we are here to help.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">Returns</p>
      <ul className="list-disc pl-6 mb-4">
        <li>
          To be eligible for a return, your item must be unused and in the same
          condition that you received it. It must also be in the original
          packaging.
        </li>
        <li>
          Certain types of goods are exempt from being returned, such as
          downloadable software products and services already rendered.
        </li>
        <li>
          A receipt or proof of purchase is required to complete the return.
        </li>
      </ul>

      <p className="section-title text-lg font-bold mt-6 mb-2">Refunds</p>
      <p className="mb-4">
        Once we receive your returned item, we will inspect it and notify you of
        the status of your refund. If your return is approved, we will initiate
        a refund to your original method of payment.
      </p>
      <p className="mb-4">
        Please note that it may take a certain number of business days for your
        bank or credit card issuer to process and post the refund to your
        account.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Non-Refundable Items
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>Custom software development services once initiated.</li>
        <li>Cloud hosting and domain services already provided.</li>
        <li>
          Downloadable software or digital products that have been delivered.
        </li>
      </ul>

      <p className="section-title text-lg font-bold mt-6 mb-2">Exchanges</p>
      <p className="mb-4">
        We only replace items if they are defective or damaged. If you need an
        exchange, please contact us with the details of your request and the
        defective item.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Shipping Costs
      </p>
      <p className="mb-4">
        You will be responsible for paying the shipping costs for returning an
        item unless the return is due to our error (e.g., wrong or defective
        item). Shipping costs are non-refundable. If you receive a refund, the
        cost of return shipping will be deducted from your refund.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">Contact Us</p>
      <p className="mb-4">
        If you have any questions about our Return and Refund Policy, please
        contact us at:
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>Email: support@shunno.com</li>
        <li>Phone: 01896192222</li>
      </ul>
    </div>
  );
};

export default ReturnRefundPolicy;
