import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <section className="relative bg-zinc-100 flex items-center pb-10 shadow-xl">
      {/* isolate transform-gpu */}
      <div className="w-full">
        <div className="container mx-auto">
          <div className="md:py-8">
            <div className="flex flex-col lg:flex-row lg:justify-between space-y-8 lg:space-y-0 w-5/6 mx-auto">
              {/* Left Section */}
              <div className="lg:w-1/2">
                <NavLink
                  to="/"
                  className="inline-block"
                  aria-label="netfee Home"
                >
                  <img
                    src="/logo/netfee2.png"
                    alt="netfee Logo"
                    loading="lazy"
                    height="30"
                    width="150"
                    // style={{ filter: "invert(100%)" }}
                  />
                </NavLink>
                <p className="mt-5 text-black text-sm sm:text-base">
                  Empowering seamless internet billing <br />
                  just the way it should be!
                </p>
                <p className="mt-5 text-gray-700 text-xs sm:text-sm">
                  Copyright © Shunno IT. 2024 All Rights Reserved
                </p>
              </div>

              {/* Right Section */}
              <div className="lg:w-1/2 flex flex-col lg:flex-row lg:justify-center space-y-6 lg:space-y-0 lg:space-x-12 text-gray-700">
                <div>
                  <ul className="space-y-2 font-bold">
                    <li>
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/about-us"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/support-team"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Support Team
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/gallery"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Gallery
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/features"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Features
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/pricing"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Pricing
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="space-y-2 font-bold">
                    <li>
                      <NavLink
                        to="/privacy-policy"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Privacy Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/terms-conditions"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Terms & Conditions
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/return-refund-policy"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Return & Refund Policy
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contact-us"
                        className={({ isActive }) =>
                          isActive ? "text-black font-semibold" : "hover"
                        }
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
