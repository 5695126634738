import React from "react";
import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-900 via-purple-900 to-black overflow-hidden text-white">
      {/* Animated Background Elements */}
      <div className="absolute top-0 left-0 w-96 h-96 bg-purple-700 blur-3xl rounded-full opacity-30 animate-pulse"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-indigo-500 blur-3xl rounded-full opacity-30 animate-pulse delay-2000"></div>

      {/* Content */}
      <div className="z-10 text-center space-y-6">
        <h1 className="text-9xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-purple-300 to-indigo-200 animate-gradient-x">
          404
        </h1>
        <p className="text-xl text-gray-300">
          Oops! The page you’re looking for doesn’t exist.
        </p>
        <Link
          to="/"
          className="inline-block px-8 py-3 text-lg font-medium text-gray-900 bg-gradient-to-r from-purple-300 to-indigo-400 rounded-lg shadow-lg hover:scale-105 hover:shadow-2xl transition-all duration-300"
        >
          Back to Home
        </Link>
      </div>

      {/* Floating Shapes */}
      <div className="absolute -top-10 -left-10 w-72 h-72 bg-gradient-to-br from-pink-500 to-red-500 opacity-20 rounded-full animate-bounce"></div>
      <div className="absolute -bottom-10 -right-10 w-72 h-72 bg-gradient-to-br from-blue-500 to-green-500 opacity-20 rounded-full animate-bounce delay-1500"></div>
    </div>
  );
};

export default NoPage;
