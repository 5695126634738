import React from "react";
import PricingSection from "../components/pricing/PricingSection";

const Pricing = () => {
  return (
    <section className="py-24" id="pricing">
      <PricingSection />
    </section>
  );
};

export default Pricing;
