import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy p-24 md:p-24 bg-gray-50 shadow-xl text-gray-700 container w-5/6 mx-auto">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">PRIVACY POLICY</h2>
      <p className="font-semibold text-gray-700 mb-4">
        The Shunno Software Privacy Policy was updated on March 24, 2022.
      </p>
      <p className="text-gray-700 mb-6">
        Please read carefully and familiarize yourself with this Privacy Policy
        to use Shunno Software application and contact us at{" "}
        <a
          href="mailto:info@shunnoit.com"
          className="text-fuchsia-600 hover:underline"
        >
          info@shunnoit.com
        </a>{" "}
        if you have any questions.
      </p>

      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Collection and Use of Personal Information
      </h3>
      <p className="text-gray-700 mb-6">
        This privacy policy sets out how Shunno Software collects, uses,
        discloses, stores, and protects any information that you provide when
        you subscribe to use our service(s) and application(s). Shunno Software
        is committed to ensuring that your private data is protected and we do
        not share any of your information with third parties without your
        consent. We may ask you to provide certain information depending on the
        content and services you use so that we can identify you and provide you
        appropriate service.
      </p>

      <h4 className="font-bold text-gray-800 mb-2">
        What personal information we collect:
      </h4>
      <ul className="list-disc pl-6 mb-6 text-gray-700">
        <li>
          When you register an account to get Shunno Software services, you may
          have to provide us some of your personal information including your
          name, organization name, address, email address, phone number, date of
          birth, National Identity Number, and passport size photograph.
        </li>
        <li>
          We may collect your location information and device identity to
          identify you concisely to provide our services.
        </li>
        <li>
          When you log in to Shunno Software, we provide an authentication
          number along with your user ID and other information that is saved to
          your device to let you use Shunno Software app securely for the entire
          session. During log out, we erase all data provided during login.
        </li>
        <li>
          During your monthly bill payment, our payment gateways require your
          debit/credit card information or your mobile banking information to
          successfully complete online bill payments.
        </li>
        <li>
          When you communicate with our support team, you may share text, photos
          taken from your device’s camera or storage, documents, or media files
          to describe issues with our services. These depend on your intention;
          we do not automatically collect such files without your consent.
        </li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        How we use your personal information
      </h3>
      <p className="text-gray-700 mb-6">
        We may use your personal information for the following purposes with
        your consent:
      </p>
      <ul className="list-disc pl-6 mb-6 text-gray-700">
        <li>
          To verify your identity, verify your bill payments, and provide
          specific services that you bought from us.
        </li>
        <li>
          To notify you periodically about service updates, changes, purchases,
          billings, dues, payments, and other activities through notifications,
          emails, messages, phone calls, and other communicative ways you
          allowed by subscription.
        </li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Collection and Use of Non-Personal Information
      </h3>
      <p className="text-gray-700 mb-6">
        We may collect some non-personal information and store it to provide you
        with standard quality services. Non-personal information that we collect
        includes:
      </p>
      <ul className="list-disc pl-6 mb-6 text-gray-700">
        <li>
          Text messages, documents, and media files that you shared with us at
          different times and for different purposes.
        </li>
        <li>
          Your location information to keep logs of your signing activities and
          secure your signing in by making alerts to you.
        </li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Controlling Your Personal Information
      </h3>
      <p className="text-gray-700 mb-6">
        You may choose to restrict the collection or use of your personal
        information in the following ways:
      </p>
      <ul className="list-disc pl-6 mb-6 text-gray-700">
        <li>
          Whenever you are asked to fill in a form on the application, look for
          the fields you want or do not want the information to be used by our
          services.
        </li>
        <li>
          If you have previously agreed to us using your personal information
          for service purposes, you may change your mind at any time by writing
          to us.
        </li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Use of Third-Party Library or SDKs
      </h3>
      <ul className="list-disc pl-6 mb-6 text-gray-700">
        <li>
          <strong>Charts:</strong> for showing various statistics within a
          chart.
        </li>
      </ul>

      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Protection of Your Information
      </h3>
      <p className="text-gray-700 mb-6">
        Shunno Software is committed to ensuring the security of your data that
        you provide. To prevent unauthorized access or disclosure, we store your
        data on our server, which complies with all standard security
        parameters.
      </p>

      <h3 className="text-xl font-semibold text-gray-700 mb-4">
        Your Privacy Rights
      </h3>
      <p className="text-gray-700 mb-6">
        We do not share, sell, disclose, distribute, or lease any of your
        personal information to any third parties unless we have your consent or
        are required by law to do so. You may request any time to correct,
        erase, or permanently remove all of your information that you provided
        us, and we are obliged to process your request. You can take legal
        action against us if we violate any part of this privacy policy.
      </p>

      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Privacy Queries
      </h3>
      <p className="text-gray-700 mb-6">
        If you do not understand or agree with the privacy policy, we strongly
        recommend you not download, install, or use Shunno Software application.
        If you have any suggestions or queries about this privacy policy, please
        contact us. Shunno Software may change this privacy policy from time to
        time by updating this document. You should check this policy regularly
        to ensure that you are not in conflict with any updates. This privacy
        policy is effective from March 24, 2022.
      </p>

      <a
        href="/"
        className="inline-block bg-fuchsia-500 text-white py-3 px-6 rounded-lg font-semibold hover:bg-blue-600"
      >
        Go to Home
      </a>
    </div>
  );
};

export default PrivacyPolicy;
