import React from "react";
import CountUp from "react-countup";

const TrustedSection = () => {
  return (
    <div className="relative md:w-5/6 mx-auto text-gray-700 bg-gray-50 py-10 px-8 md:px-16 overflow-hidden h-full">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src="/images/Bangladesh-ISP.webp"
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover"
        />
        {/* Overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-fuchsia-800 to-gray-900 opacity-80"></div>
      </div>

      <div className="relative z-10 max-w-6xl mx-auto">
        {/* Heading Section */}
        <div className="text-center md:text-left mb-12">
          <h4 className="text-fuchsia-400 text-lg uppercase tracking-wide font-semibold">
            Our track record
          </h4>
          <h2 className="text-3xl md:text-5xl font-extrabold tracking-tight mt-2 text-white">
            Trusted by thousands of Internet Service Provider
          </h2>
          <p className="mt-4 text-gray-300 text-lg">
            NetFee is designed to revolutionize the ISP billing process. Enhance
            efficiency, track payments, and grow your client base effortlessly.
          </p>
        </div>

        {/* Stats Section */}
        <div className="flex items-center justify-around">
          {/* Single Stat */}
          <div className="flex flex-col items-center animate-fade-up">
            <h3 className="text-5xl font-bold text-white">
              <CountUp end={800} duration={2.5} separator="," />+
            </h3>
            <p className="text-gray-300 mt-2">Active User</p>
          </div>

          <div className="flex flex-col items-center animate-fade-up">
            <h3 className="text-5xl font-bold text-white">
              <CountUp end={99.99} duration={3} decimals={2} />%
            </h3>
            <p className="text-gray-300 mt-2">Uptime Guarantee</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrustedSection;
