import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneVolume,
  faUsersViewfinder,
  faBell,
  faEye,
  faMoneyBill1Wave,
  faNoteSticky,
  faClipboard,
  faCommentDots,
  faAddressBook,
  faCircleUser,
  faHandshake,
  faMobileScreenButton,
  faLifeRing,
  faWifi,
  faSun,
  faDiagramProject,
  faServer,
  faUsers,
  faEnvelope,
  faChartLine,
  faCode,
} from "@fortawesome/free-solid-svg-icons";

const features = [
  { icon: faPhoneVolume, title: "Support 24/7" },
  { icon: faUsersViewfinder, title: "User Friendly" },
  { icon: faBell, title: "Notifications" },
  { icon: faCode, title: "Payment Gateway Integration" },
  { icon: faEye, title: "Accessibility" },
  { icon: faMoneyBill1Wave, title: "Web Hook Payment System" },
  { icon: faUsers, title: "Client Management" },
  { icon: faNoteSticky, title: "Billing Management" },
  { icon: faEnvelope, title: "Client Support & Ticketing" },
  { icon: faClipboard, title: "Reports" },
  { icon: faCommentDots, title: "SMS Module" },
  { icon: faAddressBook, title: "Basic Client Portal" },
  { icon: faCircleUser, title: "Accounts Management" },
  { icon: faHandshake, title: "Reseller Panel + App" },
  { icon: faMobileScreenButton, title: "Client Mobile App" },
  { icon: faLifeRing, title: "Customer Support Panel" },
  { icon: faWifi, title: "PPPoE & Static" },
  { icon: faSun, title: "Salary Management" },
  { icon: faServer, title: "Multiple Mikrotik Add" },
  { icon: faChartLine, title: "Customer Live Bandwidth" },
  { icon: faDiagramProject, title: "Network Diagram" },
];

const FeaturesSection = () => {
  // const positions = [
  //   { x: -160, y: -60 },
  //   // "Support 24/7",
  //   { x: 80, y: -160 },
  //   // "User Friendly"
  //   { x: -150, y: 80 },
  //   // Notifications
  //   { x: 190, y: 70 },
  //   // Netfee Support
  //   { x: -60, y: 180 },
  //   // Accessibility
  //   { x: 130, y: 330 },
  //   // Web Hook Payment System
  //   { x: -70, y: 330 },
  //   // Client Management
  //   { x: 190, y: -70 },
  //   // Billing Management
  //   { x: -300, y: -190 },
  //   // Client Support & Ticketing
  //   { x: 350, y: -150 },
  //   // Reports
  //   { x: 80, y: 180 },
  //   // SMS Module
  //   { x: -150, y: -290 },
  //   // Basic Client Portal
  //   { x: 300, y: 270 },
  //   // Accounts Management
  //   { x: -340, y: 130 },
  //   // Reseller Panel + App
  //   { x: 30, y: -310 },
  //   // Client Mobile App
  //   { x: -350, y: -20 },
  //   // Customer Support Panel
  //   { x: 380, y: 130 },
  //   // PPPoE & Static
  //   { x: -50, y: -160 },
  //   // Salary Control
  //   { x: 220, y: -280 },
  //   // Multiple Mikrotik Add
  //   { x: -250, y: 270 },
  //   // Customer Live Bandwidth
  //   { x: 390, y: -20 },
  //   // Network Diagram
  // ];

  return (
    <div className="relative flex flex-col items-center justify-center text-white md:pt-12 pt-20">
      {/* Mobile View */}
      <div className="text-center">
        <h1 className="text-3xl text-gray-700 font-extrabold md:mt-10 mb-5 border-b-4 border-fuchsia-600 w-32 mx-auto mb-2">
          Features
        </h1>
        <div className="grid grid-cols-2 lg:grid-cols-5 md:grid-cols-4 gap-5 px-4 mt-5">
          {features.map((feature, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center text-center p-4 bg-zinc-50 shadow-lg hover:shadow-xl text-gray-700 rounded-lg transition-transform duration-500 hover:scale-105"
            >
              <FontAwesomeIcon
                icon={feature.icon}
                className="text-indigo-500 text-3xl mb-3"
              />
              <p className="text-sm font-semibold">{feature.title}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Desktop View */}
      {/* <div
        className="desktop-feature md:my-5 flex-col items-center justify-center relative w-5/6 mx-auto text-gray-600"
        style={{ height: "900px" }}
      > */}
      {/* <h1 className="absolute top-4 font-extrabold text-gray-700 text-4xl text-center pb-2 border-b-4 border-fuchsia-600">
          Features
        </h1> */}
      {/* <div className="absolute z-10 flex items-center justify-center w-28 h-28 lg:w-36 lg:h-36 text-center text-lg lg:text-2xl font-bold text-white bg-purple-700 rounded-full shadow-lg shadow-purple-500/50">
          <img
            src="/logo/netfee logo.png"
            alt="NetFee Logo"
            className="w-20"
            style={{ filter: "invert(100%)" }}
          />
        </div> */}

      {/* {features.map((feature, index) => {
          const { x, y } = positions[index];
          return (
            <div
              key={index}
              className="absolute z-20 flex bg-indigo-300 flex-col items-center mt-5 justify-center text-center transition-transform duration-500 hover:scale-110"
              style={{
                transform: `translate(${x}px, ${y}px)`,
              }}
            >
              <div className="flex items-center bg-gray-700 justify-center w-16 h-16 lg:w-20 lg:h-20 p-3 text-xl lg:text-2xl text-white rounded-full shadow-md shadow-purple-400/30">
                <FontAwesomeIcon icon={feature.icon} />
              </div>
              <p className="mt-2 text-xs text-black lg:text-sm xl:text-base">
                {feature.title}
              </p>
            </div>
          );
        })} */}
      {/* </div> */}
    </div>
  );
};

export default FeaturesSection;
