import React from "react";

const PricingSection = () => {
  const pricingData = [
    { package: "P1", customers: "100", otc: "4,000 TK", monthly: "600 TK" },
    { package: "P2", customers: "200", otc: "4,000 TK", monthly: "1,000 TK" },
    { package: "P3", customers: "300", otc: "4,000 TK", monthly: "1,300 TK" },
    { package: "P4", customers: "400", otc: "4,000 TK", monthly: "1,700 TK" },
    { package: "P5", customers: "500", otc: "6,000 TK", monthly: "2,000 TK" },
    { package: "P6", customers: "800", otc: "6,000 TK", monthly: "2,600 TK" },
    { package: "P7", customers: "1,000", otc: "6,000 TK", monthly: "3,000 TK" },
    {
      package: "P8",
      customers: "1,300",
      otc: "10,000 TK",
      monthly: "3,500 TK",
    },
    {
      package: "P9",
      customers: "1,500",
      otc: "10,000 TK",
      monthly: "4,000 TK",
    },
    {
      package: "P10",
      customers: "2,000",
      otc: "10,000 TK",
      monthly: "5,000 TK",
    },
    {
      package: "P11",
      customers: "3,000",
      otc: "10,000 TK",
      monthly: "6,500 TK",
    },
    {
      package: "P12",
      customers: "5,000",
      otc: "10,000 TK",
      monthly: "9,000 TK",
    },
  ];

  return (
    <section className="mt-5 w-5/6 mx-auto" id="pricing">
      <div className="container mx-auto">
        <div className="text-center mb-10">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-700">
            Great Pricing Plans
          </h2>
          <div className="bg-fuchsia-600 w-24 md:w-32 h-1 mt-2 mx-auto"></div>
          <p className="text-base md:text-lg text-gray-600 mt-4">
            We have some exciting packages and plans for you.
          </p>
        </div>

        {/* Responsive Table */}
        <div className="overflow-x-auto shadow-lg rounded-lg">
          <table className="table-auto w-full border border-fuchsia-600 text-left">
            <thead className="bg-fuchsia-500 text-white">
              <tr>
                <th className="py-3 px-4 md:px-6 text-center">Package</th>
                <th className="py-3 px-4 md:px-6 text-center">
                  Customer Limit
                </th>
                <th className="py-3 px-4 md:px-6 text-center">OTC Charge</th>
                <th className="py-3 px-4 md:px-6 text-center">Monthly Fee</th>
              </tr>
            </thead>
            <tbody>
              {pricingData.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-white" : "bg-fuchsia-50"
                  } text-gray-800`}
                >
                  <td className="py-3 px-4 md:px-6 text-center font-bold">
                    {row.package}
                  </td>
                  <td className="py-3 px-4 md:px-6 text-center">
                    {row.customers}
                  </td>
                  <td className="py-3 px-4 md:px-6 text-center">{row.otc}</td>
                  <td className="py-3 px-4 md:px-6 text-center">
                    {row.monthly}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
