import React from "react";
import { FaFacebook, FaLinkedin, FaPhoneAlt } from "react-icons/fa";

const SupportSection = () => {
  const teamMembers = [
    {
      name: "Md. Rasel",
      role: "Support Executive",
      description:
        "Rasel is our Senior Executive and has developed different strategies for a variety of clients for over 3 years.",
      imgSrc: "/images/rasel.jpg",
      socialLink: [
        {
          name: "Mobile",
          icon: <FaPhoneAlt />,
          src: "01321141787",
        },
        {
          name: "Facebook",
          icon: <FaFacebook />,
          src: "https://www.facebook.com/rasel.akram.925",
        },
        {
          name: "LinkedIn",
          icon: <FaLinkedin />,
          src: "https://www.linkedin.com/in/md-rasel-ali-71b824283/",
        },
      ],
    },
    {
      name: "Parvez Joy",
      role: "Support Executive",
      description:
        "Parvez Joy has been serving in the marketing team with confidence as well as providing technical support.",
      imgSrc: "/images/parvej.jpeg",
      socialLink: [
        {
          name: "Mobile",
          icon: <FaPhoneAlt />,
          src: "01321141788",
        },
        {
          name: "Facebook",
          icon: <FaFacebook />,
          src: "https://www.facebook.com/parvezjoylucefar2",
        },
        {
          name: "LinkedIn",
          icon: <FaLinkedin />,
          src: "https://www.linkedin.com/in/parvezjoylucefar/",
        },
      ],
    },
    {
      name: "Nayem Ahmed Neel",
      role: "Support Executive",
      description:
        "Naeem Ahmed Neel is working in our Netfee ISP billing software support team. He has also been working with other software for almost 4 years.",
      imgSrc: "/images/nayem hasan neel.jpeg",
      socialLink: [
        {
          name: "Mobile",
          icon: <FaPhoneAlt />,
          src: "01321141786",
        },
        {
          name: "Facebook",
          icon: <FaFacebook />,
          src: "https://www.facebook.com/profile.php?id=61555608675583&mibextid=ZbWKwL",
        },
      ],
    },
    {
      name: "Songit Mia",
      role: "Support Executive",
      description:
        "Netfee is the software with Possibilities and Songit Mia is working as a Junior Executive to achieve higher goals.",
      imgSrc: "/images/songit.jpg",
      socialLink: [
        {
          name: "Mobile",
          icon: <FaPhoneAlt />,
          src: "01896192221",
        },
        {
          name: "Facebook",
          icon: <FaFacebook />,
          src: "https://www.facebook.com/md.sohag.rana.561548/",
        },
      ],
    },
    {
      name: "Maruf",
      role: "Support Executive",
      description:
        "Netfee is the software with Possibilities and Maruf is working as a Junior Executive to achieve higher goals.",
      imgSrc: "/images/maruf.png",
      socialLink: [
        {
          name: "Mobile",
          icon: <FaPhoneAlt />,
          src: "01896192227",
        },
        {
          name: "Facebook",
          icon: <FaFacebook />,
          src: "https://www.facebook.com/profile.php?id=100092194938110",
        },
      ],
    },
    {
      name: "Mohua Mim",
      role: "Support Executive",
      description:
        "Mohua Mim, a dedicated Support Executive at NetFee, ensures smooth user experiences with prompt, efficient, and customer-focused assistance.",
      imgSrc: "/images/mohua mim.jpeg",
      socialLink: [
        {
          name: "Mobile",
          icon: <FaPhoneAlt />,
          src: "01321141783",
        },
        {
          name: "Facebook",
          icon: <FaFacebook />,
          src: "https://www.facebook.com/profile.php?id=100086515903439",
        },
        {
          name: "LinkedIn",
          icon: <FaLinkedin />,
          src: "https://www.linkedin.com/in/mohua-mim-a61168283/",
        },
      ],
    },
    {
      name: "Mst. Taposy Chowdhury",
      role: "Support Executive",
      description:
        "Mst. Taposy Chowdhury is a skilled Support Executive at NetFee, known for her dedication, problem-solving abilities, and exceptional customer service.",
      imgSrc: "/images/taposy rabeya.jpg",
      socialLink: [
        {
          name: "Mobile",
          icon: <FaPhoneAlt />,
          src: "01321141784",
        },
        {
          name: "Facebook",
          icon: <FaFacebook />,
          src: "https://www.facebook.com/profile.php?id=100054111084317",
        },
        {
          name: "LinkedIn",
          icon: <FaLinkedin />,
          src: "https://www.linkedin.com/in/taposy-chowdhury-667167283/",
        },
      ],
    },
  ];

  return (
    <div className="container w-5/6 mx-auto flex flex-col md:mt-10 mt-5">
      <div className="text-center">
        <h2 className="text-4xl text-gray-700 font-bold font-extrabold">
          Our Expert Support Team
        </h2>
        <div className="border-b-4 border-fuchsia-600 w-72 mx-auto mt-2"></div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-8 md:p-10">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="bg-white rounded-lg border-t-2 shadow-md px-5 py-6 flex flex-col items-center text-center"
          >
            {/* Member Image */}
            <img
              src={member?.imgSrc}
              alt={member?.name}
              className="w-32 h-32 rounded-full border border-fuchsia-500 mb-4"
            />

            {/* Member Info */}
            <h3 className="text-2xl font-bold text-gray-800">{member?.name}</h3>
            <h4 className="text-lg text-indigo-700 font-semibold mb-2">
              {member?.role}
            </h4>
            <p className="text-gray-600">{member?.description}</p>

            {/* Social Links */}
            <div className="mt-4 flex items-center space-x-3 flex-wrap justify-center">
              {/* Mobile Phone Number at the top */}
              {member?.socialLink &&
                member?.socialLink.map((social, index) => (
                  <>
                    {social?.name === "Mobile" && (
                      <div key={index} className="flex justify-center">
                        <a
                          href={`tel:${social?.src}`}
                          className="text-gray-700 hover:text-fuchsia-700 text-2xl flex items-center"
                          aria-label="Call Mobile"
                        >
                          <div className="mr-2">{social?.icon}</div>
                          {social?.src}
                        </a>
                      </div>
                    )}
                    {social?.name !== "Mobile" && (
                      <a
                        key={index}
                        href={social?.src}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-700 hover:text-fuchsia-700 text-2xl"
                        aria-label={social?.name}
                      >
                        {social?.icon}
                      </a>
                    )}
                  </>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SupportSection;
