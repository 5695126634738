import React from "react";
import SupportSection from "../components/support/SupportSection";

const SupportTeam = () => {
  return (
    <div className="py-24">
      <SupportSection />
    </div>
  );
};

export default SupportTeam;
