import React from "react";

const TermsConditions = () => {
  return (
    <div className="terms-conditions p-24 md:p-24 bg-gray-50 text-gray-700 shadow-xl container w-5/6 mx-auto">
      <h2 className="text-2xl font-bold my-4">TERMS &amp; CONDITIONS</h2>

      <p className="font-semibold mb-2">
        Terms and Conditions for the Provision of Services.
      </p>
      <p className="mb-4">
        Shunno Software is a Software &amp; Website and Mobile Application
        Development service provider around the world. We have the liability to
        protect each client and provide them with the best service achievable.
        The following rules were designed to ensure that our services remain of
        the utmost excellence. Please read very carefully before ordering and/or
        using Shunno Software services. Once an order is placed with Shunno
        Software, you concur that you are in accord with and bound by the terms
        and conditions below.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Payment Information
      </p>
      <ul className="list-disc pl-6 mb-4">
        <li>
          <strong>Bank Payment:</strong> Cheques should be made payable to:
          Shunno Software.
        </li>
        <li>
          <strong>Online Payment Gateway:</strong> SSL COMMERZ.
        </li>
        <li>
          <strong>Mobile Banking:</strong>
          <ul className="list-disc pl-6">
            <li>bKash Merchant: 01911038787</li>
            <li>bKash/ Rocket/ Nagad: 01717541865 (Personal)</li>
          </ul>
        </li>
      </ul>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Pricing Policy
      </p>
      <p className="mb-4">
        All Pricing Policy only for Cloud Hosting Server provided by Shunno
        Software.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Copyright and Responsibility
      </p>
      <p className="mb-4">
        Any work done by Shunno Software for any client, which includes the
        scripts, coding, or software, unless mutually agreed, will be the
        copyright of Shunno Software and should not be commercially reproduced
        or resold without the information and authorization of Shunno Software.
      </p>
      <p className="mb-4">
        Shunno Software cannot be held responsible for copyright violations
        caused by materials and content submitted by the Client/Customer. Shunno
        Software will not be responsible for costs incurred, recompense, or loss
        of earnings due to the failure to meet arranged deadlines. Shunno
        Software will not be liable for the nonexecution of the project for
        certain reasons not in control of Shunno Software.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Website and Content
      </p>
      <p className="mb-4">
        To the fullest extent permitted at law, Shunno Software provides this
        website and its contents on an "as is" basis and we make no warranties
        until a client enters into an agreement with us on a pay-for-service
        basis. Then the agreement of the service will determine the nature of
        representations and warranty.
      </p>
      <p className="mb-4">
        Shunno Software seeks to ensure that all material on this site is
        accurate and up-to-date. However, Shunno Software is not liable for the
        content of any outside websites which may be linked to from our site.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Backups and Data Management
      </p>
      <p className="mb-4">
        Your use of this service is at your sole risk. Our backup service runs
        once a week/daily, overwrites any of our previous backups made, and only
        one week of backups are kept. Each user is solely responsible for all
        installation, maintenance, security, and backup of the operating system,
        software, files, and data used in the user's container, as well as any
        reinstalls and changes.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Investigation of Violations
      </p>
      <p className="mb-4">
        Shunno Software may investigate any reported or suspected violation of
        this Agreement, its policies, or any complaints and take any action that
        it deems appropriate and reasonable under the circumstances to protect
        its systems, facilities, customers, and/or third parties.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Actions to Violations
      </p>
      <p className="mb-4">
        Shunno Software reserves the right and has absolute discretion to
        restrict or remove from its servers any content that violates this
        Agreement or related policies or guidelines, or is otherwise
        objectionable or potentially infringing on any third party's rights or
        potentially in violation of any laws.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Revelation Rights
      </p>
      <p className="mb-4">
        To comply with applicable laws and lawful governmental requests, to
        protect Shunno Software systems and customers, or to ensure the
        integrity and operation of Shunno Software's business and systems,
        Shunno Software may access and disclose any information it considers
        necessary or appropriate.
      </p>

      <p className="section-title text-lg font-bold mt-6 mb-2">
        Warranty Disclaimer: Maintenance
      </p>
      <p className="mb-4">
        You hereby acknowledge and agree that Shunno Software reserves the right
        to temporarily suspend services for the purposes of maintaining,
        repairing, or upgrading its systems and network.
      </p>
    </div>
  );
};

export default TermsConditions;
