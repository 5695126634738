import React from "react";

const HeroSection = () => {
  return (
    <section
      id="home_wrapper"
      className="relative isolate transform-gpu bg-gray-50 md:pt-0 pt-24 flex items-center pb-10 min-h-screen shadow-md"
    >
      {/* Background Gradient */}
      <div className="absolute inset-0 -z-10 bg-[radial-gradient(80%_70%_at_50%_-20%,hsl(231,48%,48%,0.7),rgba(255,255,255,0))]"></div>

      {/* Live Tree SVG */}
      <svg
        className="hidden md:block absolute left-10 bottom-10 w-48 h-48 animate-pulse -z-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        fill="none"
      >
        <path
          d="M100 200V130"
          // stroke="#3f6212"
          // strokeWidth="5"
          strokeLinecap="round"
        />
        <circle
          cx="100"
          cy="110"
          r="40"
          fill="url(#treeGradient)"
          className="animate-grow"
        />
        <defs>
          <radialGradient id="treeGradient" cx="50%" cy="50%" r="50%">
            <stop offset="0%" stopColor="#2551c1" stopOpacity="0.8" />
            <stop offset="100%" stopColor="#cacdd42e" />
          </radialGradient>
        </defs>
      </svg>

      <svg
        className="hidden md:block absolute md:right-10 right-0 bottom-0 w-96 h-96 animate-pulse -z-10"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
        fill="none"
      >
        <path d="M100 200V130" strokeLinecap="round" />
        <circle
          cx="100"
          cy="110"
          r="40"
          fill="url(#treeGradient)"
          className="animate-grow"
        />
        <defs>
          <radialGradient id="treeGradient" cx="50%" cy="50%" r="50%">
            <stop offset="0%" stopColor="#2551c1" stopOpacity="0.8" />
            {/* Indigo center */}
            <stop offset="100%" stopColor="#cacdd42e" />
            {/* Transparent indigo */}
          </radialGradient>
        </defs>
      </svg>

      {/* Content */}
      <div className="relative mx-auto max-w-6xl text-center">
        <h1 className="bg-indigo-800 bg-clip-text text-5xl font-bold tracking-tight text-transparent md:text-7xl h-full py-4 max-w-4xl mx-auto">
          Welcome to NetFee ISP Billing Software
        </h1>
        <p className="mt-6 text-lg font-medium text-gray-700 md:text-xl text-justify px-5 md:px-1">
          NetFee is an ISP billing software.You can use the software completely
          in Bengali and English. You also manage your ISP business more easily
          and effectively. It provides comprehensive management and billing
          solutions. Including complete business management options. It has many
          other features to ensure your business runs smoothly. NetFee can give
          you the ability to manage your clients and incoming request without
          any hassle. It comes with all the modern features that can make your
          business operations more efficient.
        </p>
        <div className="mt-10 flex flex-col md:flex-row items-center justify-center gap-y-2 md:gap-x-5">
          <a href="https://app.netfeebd.com/login">
            <button className="btn bg-indigo-800 hover:bg-primary border-0 text-white">
              Admin Login
            </button>
          </a>
          <a href="https://customer.netfeebd.com">
            <button className="btn bg-indigo-800 hover:bg-primary border-0 text-white">
              Customer Login
            </button>
          </a>
          <a href="https://app.netfeebd.com/register">
            <button className="btn bg-indigo-800 hover:bg-primary border-0 text-white">
              Sign Up for the trial
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
