import React from "react";

const AboutHeroSection = () => {
  const features = [
    {
      icon: "/logo/main-service1.png",
      title: "Search opportunities",
      description:
        "NetFee is full of opportunities.You can easily find new techniques and efficient way of handling your business.",
    },
    {
      icon: "/logo/users png.png",
      title: "Reach Clients",
      description:
        "Everyday you will reach a lot of clients and customers which will help you to develop your business and gain more profit.",
    },
    {
      icon: "/logo/main-service3.png",
      title: "Activity Tracking",
      description:
        "You can track the activity of your employee very easily. Also you can track the payment history and manage the bill collectors.",
    },
  ];

  return (
    <div className="container py-10 md:w-5/6 mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between px-8">
        {/* Left Section */}
        <div className="md:w-1/2 text-center md:text-left mb-8 md:mb-0 md:pr-1">
          <h1 className="font-extrabold text-gray-700 text-4xl/[1.07] font-bold tracking-tight md:text-5xl/[1.25]">
            Only ISP Billing Management Software in Bangla Language
          </h1>
        </div>

        {/* Right Section */}
        <div className="md:w-1/2 md:ml-3 md:pl-10 md:border-l-4 md:border-dotted md:border-fuchsia-600">
          <p className="font-semibold text-gray-700 text-xl mb-6 font-extrabold">
            We provide ISP billing management software solutions to streamline
            and enhance your business operations.
          </p>
          <p className="text-gray-600 text-base">
            NetFee is an ISP billing management software can give you the
            ability to manage your clients and incoming request without any
            hassle. NetFee comes with all the modern features that can make your
            business operations more efficient.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 md:mt-16 mt-6 px-4">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`relative bg-gradient-to-br p-8 rounded-3xl border-t-2 shadow-lg hover:shadow-xl transition-all duration-500`}
            // style={{
            //   background: `linear-gradient(135deg, ${
            //     index % 3 === 0
            //       ? "#f7dbdb, #f7f7f7" // Pink to blue gradient
            //       : index % 3 === 1
            //       ? "#f7dbdb, #f7f7f7" // Yellow gradients
            //       : "#f7dbdb, #f7f7f7" // Green gradients
            //   })`,
            // }}
          >
            {/* Icon Section */}
            <div className="flex items-center justify-center w-20 h-20 bg-gray-50 rounded-full shadow-md mx-auto">
              <img
                src={feature.icon}
                alt={feature.title}
                className="w-10 h-10"
              />
            </div>

            {/* Title */}
            <h3 className="text-xl font-bold text-gray-900 text-center mt-6">
              {feature.title}
            </h3>

            {/* Description */}
            <p className="text-gray-800 text-center mt-4 leading-relaxed">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutHeroSection;
